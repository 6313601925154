import { defineStore } from 'pinia'

export const useCartStore = defineStore('cart', {
    state: () => {
      return { cart: 0 }
    },
    getters: {
      doubleCart: (state) => state.cart * 2
    },
    
    actions: {
      increment() {
        this.cart++
      },
      onFooChanged() {
        console.log('foo changed!');
      },
    },
  })
