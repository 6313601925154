<template>
  <div class="col-lg-3 col-md-4 col-12 border-end d-none d-md-block">
    <div class="pt-10 pe-lg-10">
     
      <ul class="nav flex-column nav-pills nav-pills-dark">
        <li class="nav-item">
          <a class="nav-link" aria-current="page"  :class="{ active : menu === 'your-order' }" href="/your-order"
            ><i class="feather-icon icon-shopping-bag me-2"></i>Your Orders</a
          >
        </li>
        <!-- nav item -->
        <li class="nav-item">
          <a class="nav-link" :class="{ active : menu === 'account' }" href="/account-setting"><i class="feather-icon icon-settings me-2"></i>Settings</a>
        </li>
        <!-- nav item -->
        <li class="nav-item">
          <a class="nav-link" :class="{ active : menu === 'address' }" href="/address"><i class="feather-icon icon-map-pin me-2"></i>Address</a>
        </li>
        <!-- nav item -->
        <li class="nav-item">
          <a class="nav-link" href="#"
            ><i class="feather-icon icon-credit-card me-2"></i>Payment Method</a
          >
        </li>
        <!-- nav item -->
        <li class="nav-item" >
          <a class="nav-link"  :class="{ active : menu === 'noti' }" href="/notification"><i class="feather-icon icon-bell me-2"></i>Notification</a>
        </li>
        <!-- nav item -->
        <li class="nav-item">
          <hr />
        </li>
        <!-- nav item -->
        <li class="nav-item">
          <a @click="logout()" class="nav-link"><i class="feather-icon icon-log-out me-2"></i>Log out</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: [
    'menu'
  ],
  methods: {
    async logout() {
      await axios.post('/logout', {}).then((response) => { 
        localStorage.removeItem("token");    
        this.$router.push({ path: '/' }).then(() => { this.$router.go() })
      })
    },
  }
}
</script>
