<template>
  <section class="mb-lg-14 mb-8 mt-8">
    <div class="container">
      <!-- row -->
      <div class="row">
        <!-- col -->
        <div class="col-12">
          <div>
            <div class="mb-8">
              <!-- text -->
              <h1 class="fw-bold mb-0">การชำระเงิน</h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- row -->
        <div class="row">
          <div class="col-lg-7 col-md-12">
            <!-- accordion -->
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <!-- accordion item -->
              <div class="accordion-item py-4">
                <div class="d-flex justify-content-between align-items-center">
                  <!-- heading one -->
                  <a
                    href="#"
                    class="fs-5 text-inherit collapsed h4"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="true"
                    aria-controls="flush-collapseOne"
                  >
                    <i class="feather-icon icon-map-pin me-2 text-muted"></i>ที่อยู่ผู้รับสินค้า
                  </a>
                  <!-- btn -->
                  <a href="#" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#addAddressModal"
                    >Add a new address
                  </a>
                  <!-- collapse -->
                </div>
                <div id="flush-collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionFlushExample">
                  <div class="mt-5">
                    <div class="row" v-if="addressList.length > 0">
                      <div class="col-lg-6 col-12 mb-4" v-for="item in addressList">
                        <!-- form -->
                        <div class="border p-6 rounded-3">
                          <div class="form-check mb-4">
                              <input class="form-check-input" type="radio" name="flexRadioDefault" id="homeRadio" checked v-if="item.status_default" @click="address_id = item.id"/>
                              <input class="form-check-input" type="radio" name="flexRadioDefault" id="homeRadio"  v-if="item.status_default == 0"  @click="address_id = item.id"/>
                            <label class="form-check-label text-dark" for="homeRadio"> {{ item.address_name}} </label>
                          </div>
                          <address>
                            <strong> {{ item.name }}</strong> <br />

                            {{ item.address }} <br />

                            {{ item.tumbol }} {{ item.amphur }} {{ item.province }} {{ item.remark }}<br />

                            <abbr title="Phone">P: {{ item.phone }}</abbr>
                          </address>
                          <span class="text-danger" v-if="item.status_default">Default address </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accordion item -->
              <div class="accordion-item py-4">
                <a
                  href="#"
                  class="text-inherit collapsed h5"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  <i class="feather-icon icon-clock me-2 text-muted"></i>เลือกช่องทางการจัดส่งสินค้า
                </a>
                <!-- collapse -->
                <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="tab-content" id="pills-tabContent">
                    <!-- tab pane -->
                    <div
                      class="tab-pane fade show active"
                      id="pills-today"
                      role="tabpanel"
                      aria-labelledby="pills-today-tab"
                      tabindex="0"
                    >
                      <!-- list group -->
                      <ul class="list-group list-group-flush mt-4">
                        <!-- list group item -->
                        <li
                          v-for="(item, index) in transport"
                          class="list-group-item d-flex justify-content-between align-items-center px-0 py-3"
                        >
                          <!-- col -->
                          <div class="col-4">
                            <div class="form-check">
                              <!-- form check input -->
                              <input
                                class="form-check-input"
                                type="radio"
                                v-if="index == 0"
                                name="priceTransportSelect"
                                checked
                                 @click="priceTransportSelect =priceTransport[index], transport_id = item.id"
                                id="transportSelect"
                              />
                              <input
                                class="form-check-input"
                                 name="priceTransportSelect"
                                type="radio"
                                @click="priceTransportSelect =priceTransport[index], transport_id = item.id"
                                v-if="index != 0"
                                id="transportSelect"
                              />
                              <!-- label -->
                              <label class="form-check-label" for="transportSelect">
                                <span>{{ item.name }}</span>
                              </label>
                            </div>
                          </div>

                          <!-- price_sale -->
                          <div class="col-3 text-center">฿{{ priceTransport[index] }}</div>

                          <!-- <div class="col-2 text-end">
                            <a href="#" class="btn btn-outline-gray-400 btn-sm text-white btn-success">เลือกการจัดส่งนี้</a>
                          </div> -->
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- accordion item -->
              <div class="accordion-item py-4">
                <a
                  href="#"
                  class="text-inherit h5"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  <i class="feather-icon icon-shopping-bag me-2 text-muted"></i>ฝากข้อความสำหรับการจัดส่ง
                  <!-- collapse -->
                </a>
                <div id="flush-collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="mt-5">
                    <label for="DeliveryInstructions" class="form-label sr-only">Delivery instructions</label>
                    <textarea
                      class="form-control"
                      id="DeliveryInstructions"
                      rows="3"
                      placeholder="Write delivery instructions "
                      v-model="text_transport"
                    ></textarea>
                    <p class="form-text">Add instructions for how you want your order shopped and/or delivered</p>
                    <div class="mt-5 d-flex justify-content-end">
                      <a
                        href="#"
                        class="btn btn-outline-gray-400 text-muted"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                        >Prev</a
                      >
                      <a
                        href="#"
                        class="btn btn-primary ms-2"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseFour"
                        aria-expanded="false"
                        aria-controls="flush-collapseFour"
                        >Next</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- accordion item -->
              <div class="accordion-item py-4">
                <a
                  href="#"
                  class="text-inherit h5"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  <i class="feather-icon icon-credit-card me-2 text-muted"></i>การชำระเงิน
                  <!-- collapse -->
                </a>
                <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                  <div class="mt-5">
                    <div>
                      <div class="card card-bordered shadow-none mb-2">
                        <!-- card body -->
                        <div class="card-body p-6">
                          <div class="d-flex">
                            <div class="form-check">
                              <!-- checkbox -->
                              <input
                                class="form-check-input"
                                type="radio"
                                name="payment"
                                id="paypal"
                                @click=";(payment = 'credit_card'), (fee = (total + priceTransportSelect) * 0.06)"
                              />
                              <label class="form-check-label ms-2" for="paypal"> </label>
                            </div>
                            <div>
                              <!-- title -->
                              <h5 class="mb-1 h6">ชําระผ่าน <b>บัตรเครดิต เดบิต</b> ด้วยช่องทางที่มีความปลอดภัยสูง</h5>
                              <p class="mb-0 small">ค่าธรรมเนียม {{ ((total + priceTransportSelect) * 0.06).toFixed(2) }} บาท</p>
                              <img
                                style="width: 150px"
                                src="https://www.sabaiatkan.com/wp-content/uploads/2019/06/visa-master-jcb-1240x331.jpg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- card -->
                      <div class="card card-bordered shadow-none mb-2">
                        <!-- card body -->
                        <div class="card-body p-6">
                          <div class="d-flex mb-4">
                            <div class="form-check">
                              <!-- input -->
                              <input
                                class="form-check-input"
                                type="radio"
                                checked
                                name="payment"
                                id="creditdebitcard"
                                @click=";(payment = 'promptpay'), (fee = (total + priceTransportSelect) * 0.0345)"
                              />
                              <label class="form-check-label ms-2" for="creditdebitcard"> </label>
                            </div>
                            <div>
                              <h5 class="mb-1 h6">ชําระผ่านระบบ <b>พร้อมเพย์</b> เพื่อค่าธรรมเนียมที่ประหยัดกว่า</h5>
                              <p class="mb-0 small">ค่าธรรมเนียม {{ ((total + priceTransportSelect) * 0.0345).toFixed(2) }} บาท</p>
                              <img
                                style="width: 150px"
                                src="https://www.designil.com/wp-content/uploads/2020/04/prompt-pay-logo.png"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card card-bordered shadow-none mb-2 d-none">
                        <!-- card body -->
                        <div class="card-body p-6">
                          <div class="d-flex mb-4">
                            <div class="form-check">
                              <!-- input -->
                              <input
                                class="form-check-input"
                                type="radio"
                                name="payment"
                                id="creditdebitcard"
                                @click=";(payment = 'mobile_banking_kbank'), (fee = 19)"
                              />
                              <label class="form-check-label ms-2" for="creditdebitcard"> </label>
                            </div>
                            <div>
                              <h5 class="mb-1 h6">ชําระผ่านระบบ <b>โมบายล์แบงก์กิ้ง</b> ด้วยค่าบริการคงที่ถูกที่สุด</h5>
                              <p class="mb-0 small">ค่าธรรมเนียม 19 บาท</p>
                              <img
                                style="width: 150px"
                                src="https://www.gotradehere.com/wp-content/uploads/2021/07/Screenshot-2021-07-16-144517.jpg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- card -->

                      <!-- Button -->
                      <div class="mt-5 d-flex justify-content-end">
                        <a
                          href="#"
                          class="btn btn-outline-gray-400 text-muted"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                          >ก่อนหน้า</a
                        >
                        <a href="javascript:void(0)" class="btn btn-primary ms-2" @click="paymentCheck">สั่งสินค้า</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 offset-lg-1 col-lg-4">
            <div class="mt-4 mt-lg-0">
              <div class="card shadow-sm">
                <h5 class="px-6 py-4 bg-transparent mb-0">รายการสั่งซื้อ</h5>
                <ul class="list-group list-group-flush">
                  <!-- list group item -->
                  <li v-for="(item, index) in cart" class="list-group-item px-4 py-3">
                    <div class="row align-items-center">
                      <div class="col-2 col-md-2">
                        <img
                          :src="this.domain + 'images/' + item.product.image_main"
                          alt="{{ item.product.product_name }}"
                          class="img-fluid"
                        />
                      </div>
                      <div class="col-5 col-md-5">
                        <h6 class="mb-0">{{ item.product.product_name }}</h6>
                        <span
                          ><small class="text-muted">{{ item.product.net_volume }} / kg</small></span
                        >
                      </div>
                      <div class="col-2 col-md-2 text-center text-muted">
                        <span>{{ item.amount }}</span>
                      </div>
                      <div class="col-3 text-lg-end text-start text-md-end col-md-3">
                        <span class="fw-bold">฿{{ (item.product.price_sale * item.amount).toLocaleString() }}.00</span>
                      </div>
                    </div>
                  </li>

                  <!-- list group item -->
                  <li class="list-group-item px-4 py-3">
                    <div class="d-flex align-items-center justify-content-between mb-2">
                      <div>ราคารวม</div>
                      <div class="fw-bold">฿{{ total.toLocaleString() }}.00</div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <div>ค่าจัดส่ง</div>
                      <div class="fw-bold">฿ {{ priceTransportSelect }}.00</div>
                    </div>
                    <div class="d-flex align-items-center justify-content-between">
                      <div>ค่าธรรมเนียมและบริการ</div>
                      <div class="fw-bold">฿ {{ fee.toLocaleString() }}</div>
                    </div>
                  </li>
                  <!-- list group item -->
                  <li class="list-group-item px-4 py-3">
                    <div class="d-flex align-items-center justify-content-between fw-bold">
                      <div>ที่ต้องชำระรวมสุทธิ</div>
                      <div>฿{{ (total + priceTransportSelect + fee).toLocaleString() }}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="modal fade" id="addAddressModal" tabindex="-1" aria-labelledby="addAddressModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <!-- modal content -->
      <div class="modal-content">
        <!-- modal body -->
        <div class="modal-body p-6">
          <div class="d-flex justify-content-between mb-5">
            <div>
              <!-- heading -->
              <h5 class="mb-1" id="addAddressModalLabel">New Shipping Address</h5>
              <p class="small mb-0">Add new shipping address for your order delivery.</p>
            </div>
            <div>
              <!-- button -->
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
          <!-- row -->
          <div class="row g-3">
            <div class="col-12">
              <input
                type="text"
                class="form-control"
                placeholder="Addres name ex. Home, Office"
                required=""
                v-model="address_name"
              />
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="name" aria-label="name" required="" v-model="name" />
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="Address" v-model="address" />
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="Tumbol" v-model="tumbol" />
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="Amphur" v-model="amphur" />
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="Province" v-model="province" />
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="PostCode" v-model="remark" />
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="Phone" v-model="phone" />
            </div>

            <div class="col-12">
              <!-- form check -->
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" v-model="status_default" id="flexCheckDefault" />
                <label class="form-check-label" for="flexCheckDefault"> Set as Default </label>
              </div>
            </div>

            <div class="col-12 text-end">
              <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" id="btnClose">Cancel</button>
              <button class="btn btn-primary" type="button" @click="addAddress()">Save Address</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal :okButton="textModal" title="QRCODE" v-model:visible="modalPP">
    <div class="col-12" v-if="promptpayQrCode != ''">
      <img :src="promptpayQrCode" alt="PromtPay QR Code" />
    </div>
  </Modal>

  <form id="checkoutForm" method="POST" action="https://api.cobens.co/api/credit-card">
    <input type="hidden" name="omiseToken" v-model="omiseToken" />
    <input type="hidden" name="total" />
    <input type="hidden" name="user_id" v-model="user.id" />
    <input type="hidden" name="transport_id" v-model="transport_id" />
    <input type="hidden" name="address_id" v-model="address_id" />
    <input type="hidden" name="text_transport" v-model="text_transport" />
    
    <input type="hidden" name="omiseSource" />
    <button class="d-none" type="submit" id="checkoutButton">Checkout</button>
  </form>
</template>
<script>
import axios from 'axios'
import { Modal } from 'usemodal-vue3'

export default {
  inject: ['url_api', 'url_domain_api'],
  name: 'Home',
  components: {
    Modal
  },
  data() {
    return {
      modalPP: false,
      textModal: {
        text: 'ชำระเงินเรียบร้อยแล้ว',
        onclick: () => {
          this.gotoOrder()
        },
        loading: true
      },
      api: this.url_api,
      domain: this.url_domain_api,
      cart: [],
      payment: 'promptpay',
      total: 0,
      user: [],
      transport: [],
      address: '',
      tumbol: '',
      created_by: '',
      amphur: '',
      province: '',
      phone: '',
      remark: '',
      address_name: '',
      name: '',
      status_default: false,
      omiseToken: null,
      promptpayQrCode: '',
      chargeId: '',
      fee: 0,
      weight: 0,
      priceTransport: [],
      priceTransportSelect : 0,
      addressList : [],
      address_id : "",
      transport_id : "",
      text_transport:""
    }
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.getProfile()
    }
    this.getTransport()

    OmiseCard.configure({
      publicKey: 'skey_5zeaaagucqwgfoerdhl'
      // publicKey: 'pkey_5ppr6k7fqva3wvh92l7'
      //publicKey: 'pkey_test_5psug02iiyzt87f121y'
    })

    // this.fee = (this.total + this.priceTransport) * 0.06
    // OmiseCard.configureButton('#checkout-button', {
    //   amount: this.total,
    //   currency: 'THB',
    //   buttonLabel: 'Pay '+ this.total +' THB'
    // });
  },
  methods: {
    async getProfile() {
      await axios.get('/profile', {}).then((response) => {
        if (response) {
          this.user = response.data.user
          this.getCart()
          this.getAddress()
        }
      })
    },
    async getTransport() {
      await axios.get('https://api.cobens.co/api/transport', {}).then((response) => {
        this.transport = response.data;
        this.transport_id = this.transport[0].id;
        this.getCart()
      })
    },
    async getCart() {
      await axios.get('/cart-user/' + this.user.id, {}).then((response) => {
        this.total = 0;
        this.weight = 0;
        this.cart = response.data
        for (let i = 0; i < this.cart.length; i++) {
          this.total += this.cart[i].product.price_sale * this.cart[i].amount
          this.weight += this.cart[i].product.net_volume
        }
        this.priceTransport.length = this.transport.length;
        for (let i = 0; i < this.transport.length; i++) {
          for (let j = 0; j < this.transport[i].price.length; j++) {
            if (this.weight <= this.transport[i].price[j].weight) {
              this.priceTransport[i] = this.transport[i].price[j].price;
            }
          }
        }

        this.fee = (this.total + this.priceTransport[0]) * 0.0345;
        this.priceTransportSelect = this.priceTransport[0];
      })
    },
    async getAddress() {
      await axios.get('address-user/' + this.user.id, {}).then((response) => {
        if (response) {
          this.addressList = response.data;
          for(let i = 0;i < this.addressList.length; i++){
            if(this.addressList[i].status_default == '1'){
                this.address_id = this.addressList[i].id;
            }
          }
        }
      })
    },
    closeModal() {
      $('#btnClose').trigger('click')
    },
    async addAddress() {
      if (this.status_default) {
        this.status_default = 1
      } else {
        this.status_default = 0
      }
      await axios
        .post('/address/', {
          user_id: this.user.id,
          address: this.address,
          tumbol: this.tumbol,
          created_by: this.user.id,
          updated_by: this.user.id,
          amphur: this.amphur,
          province: this.province,
          phone: this.phone,
          remark: this.remark,
          address_name: this.address_name,
          name: this.name,
          status_default: this.status_default
        })
        .then((response) => {
          this.getAddress()
          this.closeModal()
        })
    },
    showPP() {
      $('#btnPP').trigger('click')
    },
    gotoOrder() {
      this.$router.push({ path: 'your-order' }).then(() => {
        this.$router.go()
      })
    },
    paymentCheck() {
      let form = document.querySelector('#checkoutForm')

      if (this.payment == 'promptpay') {
        axios
          .post('/order', {
            user_id: this.user.id,
            transport_id: this.transport_id,
            address_id: this.address_id,
            text_transport : this.text_transport,
            total: this.total + this.priceTransportSelect + this.fee
          })
          .then((response) => {
            this.promptpayQrCode = response.data.qr_code
            this.modalPP = true
          })
      }

      if (this.payment == 'credit_card') {
        OmiseCard.open({
          amount: (this.total + this.priceTransportSelect + (this.total + this.priceTransportSelect) * 0.06) * 100,
          currency: 'THB',
          defaultPaymentMethod: 'credit_card',
          onCreateTokenSuccess: (nonce) => {
           
            form.omiseSource.value = nonce
            form.total.value = this.total + this.priceTransportSelect + (this.total + this.priceTransportSelect) * 0.06
            // if (nonce.startsWith("tokn_")) {
            //     this.omiseToken = nonce;
            // } else {
            //     form.omiseSource.value = nonce;
            // };
            form.submit()
          }
        })
      }

      // if (this.payment == 'mobile_banking_kbank') {
      //   Omise.setPublicKey('pkey_5ppr6k7fqva3wvh92l7')

      //   Omise.createSource(
      //     'internet_banking_scb',
      //     {
      //       amount: 400000,
      //       currency: 'THB'
      //     },
      //     function (statusCode, response) {
      //       console.log(response)
      //       form.amount.value = 400000
      //       form.omiseSource.value = response.id
      //       form.submit()
      //     }
      //   )
      // }

      //  OmiseCard.configure({
      //     publicKey: 'pkey_5ppr6k7fqva3wvh92l7'
      //   });

      // OmiseCard.configureButton('#checkout-button', {
      //   amount: this.total,
      //   currency: 'THB',
      //   buttonLabel: 'Pay '+ this.total +' THB'
      // });
      //  OmiseCard.setPublicKey("pkey_5ppr6k7fqva3wvh92l7");
      //    var form = document.querySelector("#checkoutForm");

      // Omise.setPublicKey("pkey_5ppr6k7fqva3wvh92l7");

      // Omise.createSource('promptpay', {
      //   "amount": 400000,
      //   "currency": "THB"
      // }, function(statusCode, response) {
      //   console.log(response)

      //  form.money.value = 400000;
      //  form.omiseSource.value = response.id;
      //  form.submit();
      //});

      // Omise.createSource('promptpay', {
      //              "amount": c_amount,
      //              "currency": "THB"
      //            }, function(statusCode, response) {
      //                if(statusCode == '200'){
      //                    form.omiseSource.value = response.id;
      //                }
      //                form.submit();
      //            });
    }
  }
}
</script>

