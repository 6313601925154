<template>
  <div class="">
    <DefaultNav/>
    <slot />
   <DefaultFooter/>
  </div>
</template>

<script>
import DefaultFooter from './DefaultFooter.vue'
import DefaultNav from './DefaultNav.vue'

export default {
  components: {
    DefaultNav,
    DefaultFooter
  }
}
</script>

<style lang="scss">
/* Sticky footer */
html {
  position: relative;
  min-height: 100%;
}


</style>
