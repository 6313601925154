<template>
  <main>
    <section>
      <div class="container">
        <div class="row">
          <MenuAccount menu="your-order" />

          <div class="col-lg-9 col-md-8 col-12">
            <div class="py-6 p-md-6 p-lg-10">
              <h2 class="mb-6">Your Orders</h2>

              <div class="table-responsive border-0">
                <table class="table mb-0 text-nowrap">
                  <thead class="table-light">
                    <tr>
                      <th class="border-0 text-center">เลขที่สั่งซื้อ</th>
                      <th class="border-0 text-center">วันที่</th>

                      <th class="border-0 text-center">สถานะการสั่งซื้อ</th>

                      <th class="border-0 text-center">จำนวนเงิน</th>
                      <th class="border-0 text-center">รายละเอียด</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in order">
                      <td class="align-middle border-top-0">
                        <a href="#" class="text-inherit">#{{ item['number'] }}</a>
                      </td>
                      <td class="align-middle border-top-0">{{ item['order_date'] }}</td>
                      <td class="align-middle border-top-0 text-center">
                        <span class="badge bg-warning" v-if="item['omise']['status'] == 'pending' && item['status'] == 1"
                          >ยังไม่ชำระ</span
                        >
                        <span class="badge bg-warning" v-if="item['omise']['status'] == 'successful' && item['status'] == 2"
                          >ขำระเงินแล้ว</span
                        >
                        <span class="badge bg-warning" v-if="item['omise']['status'] == 'successful' && item['status'] == 3"
                          >กำลังจัดส่ง</span
                        >
                        <span class="badge bg-warning" v-if="item['omise']['status'] == 'successful' && item['status'] == 4"
                          >สำเร็จ</span
                        >
                        <span class="badge bg-warning" v-if="item['omise']['status'] == 'successful' && item['status'] == 5"
                          >ส่งคืน/คืนเงิน</span
                        >
                      </td>

                      <td class="align-middle border-top-0 text-right" style="text-align: right">
                        ฿{{ item['total_price'].toLocaleString() }}
                      </td>
                      <td class="text-muted align-middle border-top-0 text-center">
                        <a href="#" class="text-inherit" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="View"
                          ><i class="feather-icon icon-eye"></i
                        ></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from 'axios'
import MenuAccount from './MenuAccount'
export default {
  inject: ['url_api'],
  components: {
    MenuAccount
  },
  data() {
    return {
      order: [],
      user: []
    }
  },
  mounted() {
    document.title = 'Your Order'
    this.getProfile()
  },
  methods: {
    async getProfile() {
      await axios.get('/profile', {}).then((response) => {
        if (response) {
          this.user = response.data.user
          console.log(this.user)
          this.getOrder()
        } else {
          this.$router.push({ path: 'login' })
        }
      })
    },
    async getOrder() {
      await axios.get('/your-order', { params: { user_id: this.user.id } }).then((response) => {
        if (response) {
          this.order = response.data
          console.log(this.order)
        }
      })
    }
  }
}
</script>
