<template>
<metainfo>
    <template v-if="content" v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `Cobens` }}</template>
  </metainfo>
  <component :is="layout">
    <router-view/>
  </component>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@500&display=swap');
</style>
<script>
import '@/scss/app.scss'
import '@/assets/libs/bootstrap-icons/font/bootstrap-icons.css'
import '@/assets/libs/feather-webfont/dist/feather-icons.css'
import '@/assets/libs/slick-carousel/slick/slick.css'
import '@/assets/libs/slick-carousel/slick/slick-theme.css'
import '@/assets/libs/simplebar/dist/simplebar.min.css'
import '@/assets/libs/nouislider/dist/nouislider.min.css'
import '@/assets/libs/tiny-slider/dist/tiny-slider.css'
import '@/assets/libs/dropzone/dist/min/dropzone.min.css'
import '@/assets/libs/prismjs/themes/prism-okaidia.min.css'
import '@/assets/css/theme.min.css'


import * as jquery from '@/assets/libs/jquery/dist/jquery.min.js'
import * as bootstrap from '@/assets/libs/bootstrap/dist/js/bootstrap.bundle.min.js'
{/* import * as countdown from '@/assets/libs/jquery-countdown/dist/jquery.countdown.min.js' */}
{/* import * as slick from '@/assets/libs/slick-carousel/slick/slick.min.js' */}
import * as simplebar from '@/assets/libs/simplebar/dist/simplebar.min.js'
import * as nouislider from   '@/assets/libs/nouislider/dist/nouislider.min.js'
import * as wNumb from '@/assets/libs/wnumb/wNumb.min.js'
import * as rater from '@/assets/libs/rater-js/index.js'
import * as prism from '@/assets/libs/prismjs/prism.js'
import * as prismscss from '@/assets/libs/prismjs/components/prism-scss.min.js'
import * as toolbar from '@/assets/libs/prismjs/plugins/toolbar/prism-toolbar.min.js'
import * as clipboard from '@/assets/libs/prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.min.js'
import * as tiny from '@/assets/libs/tiny-slider/dist/min/tiny-slider.js'
import * as dropzone from  '@/assets/libs/dropzone/dist/min/dropzone.min.js'
import * as flatpickr from '@/assets/libs/flatpickr/dist/flatpickr.min.js'
{/* import * as jquery2 from '@/assets/libs/inputmask/dist/jquery.inputmask.min.js' */}
import { useMeta } from 'vue-meta'
export default {
  computed: {
    layout() {
      return `layout-${this.$route.meta.layout || 'default'}`
    }
  }
}
</script>
