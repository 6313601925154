<template>
  <main>
    <!-- section -->

    <section class="my-lg-14 my-8">
      <!-- container -->
      <div class="container">
        <!-- row -->
        <div class="row justify-content-center align-items-center">
          <div class="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
            <!-- img -->
            <img src="../assets/images/svg-graphics/signup-g.svg" alt="" class="img-fluid" />
          </div>

          <div class="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1" v-if="step == 1">
            <div class="mb-lg-9 mb-5">
              <h1 class="mb-1 h2 fw-bold">สมัครสมาชิก</h1>
              <p>Welcome to Cobens! Enter your email to get started.</p>
            </div>
            <!-- form -->
            <form>
              <div class="row g-3">
                <div class="col-12">
                  <input type="text" class="form-control" placeholder="ชื่อ" v-model="fname" required />
                </div>
                <div class="col-12">
                  <input type="text" class="form-control" placeholder="นามสกุล" v-model="lname" required />
                </div>

                <div class="col-12">
                  <textarea class="form-control" placeholder="ที่อยู่" v-model="address"></textarea>
                </div>
                <div class="col-12">
                  <select class="form-control" v-model="province" placeholder="จังหวัด">
                    <option v-for="item in provinceList" :value="item.code">{{ item.name_th }}</option>
                  </select>
                </div>
                <div class="col-12">
                  <input type="text" class="form-control" placeholder="รหัสไปรษณีย์" v-model="postcode" required />
                </div>
                <div class="col-12">
                  <input type="text" class="form-control" placeholder="เบอร์โทรศัพท์" v-model="phone" required />
                </div>

                <div class="col-12 mt-6">
                  <p>ข้อมูลเข้าสู่ระบบ</p>
                  <input type="email" class="form-control" id="inputEmail4" placeholder="Email" required v-model="email" />
                </div>
                <div class="col-12">
                  <input
                    type="password"
                    class="form-control"
                    id="inputPassword4"
                    placeholder="Password"
                    required
                    v-model="password"
                  />
                </div>
                <div class="col-12"><input type="checkbox" v-model="typeUser" /> บุคคลากรทางการแพทย์</div>
                <div class="col-12" v-if="typeUser">
                  <select class="form-control" placeholder="ระบุประเภทบุคคลากรทางการแพทย์" v-model="doctorType">
                    <option>ระบุประเภทบุคคลากรทางการแพทย์</option>
                    <option value="1">แพทย์</option>
                    <option value="2">ทันตแพทย์</option>
                    <option value="3">เภสัชกร</option>
                    <option value="4">พยาบาล</option>
                  </select>
                </div>
                <div class="col-12" v-if="typeUser">
                  <p>รูปบัตรประชาชน</p>
                  <input type="file" accept="image/*" label="รูปภาพ" @change="uploadFile($event)" ref="image_path">

                  <p>*เฉพาะบุคคลากรทางการแพทย์ท่ีต้องการสั่งซ้ือยาโปรดแนบรูปบัตรประชาชน</p>
                </div>
                <!-- btn -->
                <div class="col-12 d-grid"><button type="button" @click="register()" class="btn btn-primary">สมัครสมาชิก</button></div>

                <!-- text -->
                <p>
                  <small
                    >By continuing, you agree to our <a href="#!"> Terms of Service</a> & <a href="#!">Privacy Policy</a></small
                  >
                </p>
              </div>
            </form>
          </div>

          <div class="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1" v-if="step == 2">
            <div class="mb-lg-9 mb-5">
              <h1 class="mb-1 h2 fw-bold">ยืนยันสมัครสมาชิก</h1>
              <p>โปรดยืนยันการสมัครสมาชิกโดยกดยืนยัน Link ที่ส่งให้ทางอีเมล</p>
               <div class="col-12 d-grid"><button type="button" @click="step=3" class="btn btn-primary">ส่ง Link ไปใหม่ </button></div>
            </div>
          </div>
           <div class="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1" v-if="step == 3">
            <div class="mb-lg-9 mb-5">
              <h1 class="mb-1 h2 fw-bold">สมัครสมาชิกเรียบร้อยแล้ว</h1>
              <p>ขอบคุณคุณลูกค้าที่สมัครสมาชิกกับเรา ขอให้สนุกกับการ Shopping ที่ Cobens</p>
              <div class="col-12" style="    display: flex!important;
                    justify-content: space-between;">
                <button type="button" class="btn btn-gray-400 w-40">กลับสู่หน้าหลัก</button>
                <button type="button" class="btn btn-primary w-40">เข้าสู่ระบบ</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  inject: ['url_api'],
  data() {
    return {
      step:1,
      email: '',
      password: '',
      fname: '',
      lname: '',
      address: '',
      province: '',
      postcode: '',
      typeUser: false,
      provinceList: [],
      province: '',
      image_path: '',
      doctorType: ''
    }
  },
  watch: {
    typeUser(val) {}
  },
  created() {
    this.getProvince()
  },
  methods: {
    uploadFile(event) {
      this.image_path = event.target.files[0]
    },
    async getProvince() {
      await axios.get('/province').then((response) => {
        this.provinceList = response.data
        this.province = 10000000
      })
    },
    async register() {
      if (!this.typeUser) {
        await axios
          .post('/register', {
            first_name: this.fname,
            last_name: this.lname,
            email: this.email,
            password: this.password,
            password_confirmation: this.password,
            role: 'member',
            address: this.address,
            phone: this.phone,
            doctor_type: this.doctorType,
            postcode: this.postcode
          })
          .then((response) => {
            this.step = 2;
            // this.$router.push({ path: 'login' }).then(() => {
            //   this.$router.go()
            // })
          })
      } else {
        const member = new FormData()
        member.append('doctor_file', this.image_path)
        member.append('first_name', this.fname)
        member.append('last_name', this.lname)
        member.append('email', this.email)
        member.append('password', this.password)
        member.append('role', 'member')
        member.append('doctor_type', this.doctorType)
        member.append('address', this.address)
        member.append('phone', this.phone)
        member.append('postcode', this.postcode)

        axios
          .post('/register', member, {
            headers: {
              accept: 'application/json',
              'Accept-Language': 'en-US,en;q=0.8',
              'Content-Type': 'multipart/form-data',
              enctype: 'multipart/form-data',
              'Access-Control-Allow-Origin': '*'
            }
          })
          .then((res) => {  this.step = 2; })
          .catch((error) => console.log(error.data.message))
      }
    }
  }
}
</script>
