<template>
  <div>
    <header>
      <div class="bg-light py-1">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-12 text-center text-md-start">
              <span>{{ topBar }}</span>
            </div>
            <div class="col-6 text-end d-none d-md-block">
              <div class="dropdown">
                <a
                  class="dropdown-toggle text-decoration-none text-muted"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="me-1"> </span> <i class="bi bi-shop"></i> สําหรับร้านค้า
                </a>

                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" href="https://apps.cobens.co/login" target="_blank"
                      ><i class="bi bi-lock pe-2"></i> ล็อคอิน / เข้าสู่ระบบ</a
                    >
                  </li>
                  <li>
                    <a class="dropdown-item" href="https://apps.cobens.co/login" target="_blank"
                      ><i class="bi bi-person-plus pe-2"></i> สมัครเป็นร้านค้าใหม่</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="navbar navbar-light py-lg-4 pt-3 px-0 pb-0">
        <div class="container">
          <div class="row w-100 align-items-center g-lg-2 g-0">
            <div class="col-xxl-2 col-lg-3">
              <a href="/" class="navbar-brand d-none d-lg-block">
                <img src="@/assets/images/logo/cobens-logo.svg" alt="eCommerce HTML Template" />
              </a>
              <div class="d-flex justify-content-between w-100 d-lg-none">
                <a href="/" class="navbar-brand">
                  <img class="w-65" src="@/assets/images/logo/cobens-logo.svg" alt="eCommerce HTML Template" />
                </a>

                <div class="d-flex align-items-center lh-1">
                  <div class="list-inline me-2">
                    <div class="list-inline-item">
                      <a href="#!" class="text-muted" data-bs-toggle="modal" data-bs-target="#userModal">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-user"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                      </a>
                    </div>
                    <div class="list-inline-item">
                      <a
                        class="text-muted position-relative"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        href="#offcanvasExample"
                        role="button"
                        aria-controls="offcanvasRight"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-shopping-bag"
                        >
                          <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                          <line x1="3" y1="6" x2="21" y2="6"></line>
                          <path d="M16 10a4 4 0 0 1-8 0"></path>
                        </svg>
                        <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                          1
                          <span class="visually-hidden">unread messages</span>
                        </span>
                      </a>
                    </div>
                  </div>
                  <!-- Button -->
                  <button
                    class="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#navbar-default"
                    aria-controls="navbar-default"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span class="icon-bar top-bar mt-0"></span>
                    <span class="icon-bar middle-bar"></span>
                    <span class="icon-bar bottom-bar"></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-xxl-5 col-lg-4 d-none d-lg-block" v-if="!isAuthPage">
              <form action="#">
                <div class="input-group">
                  <input class="form-control rounded-3" type="search" value="Search for products" id="searchInput" />
                  <span class="input-group-append">
                    <button class="btn bg-white border border-start-0 ms-n10" type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-search"
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                    </button>
                  </span>
                </div>
              </form>
            </div>
            <div class="col-md-2 col-xxl-2 d-none d-lg-block" v-if="!isAuthPage">
              <button
                type="button"
                class="btn btn-outline-gray-400 text-muted"
                data-bs-toggle="modal"
                data-bs-target="#locationModal"
              >
                <i class="feather-icon icon-map-pin me-2"></i>Location
              </button>
            </div>
            <div class="col-md-3 col-xxl-3 text-end d-none d-lg-block" v-if="!isAuthPage">
              <div class="list-inline">
                <div class="list-inline-item">
                  <a href="javascript:void(0)" class="text-muted position-relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-heart"
                    >
                      <path
                        d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
                      ></path>
                    </svg>
                    <!-- <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">
                      5
                      <span class="visually-hidden">unread messages</span>
                    </span> -->
                  </a>
                </div>
                <div class="list-inline-item">
                  <!-- <a id="loginbtn"  v-if="!user" href="#!" class="text-muted" data-bs-toggle="modal" data-bs-target="#userModal"> -->
                  <a v-if="!user" href="/login" class="text-muted">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                  </a>
                  <router-link v-if="user" to="account-setting" class="text-success">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    {{ profileName }}
                  </router-link>
                </div>
                <div class="list-inline-item">
                  <a
                    class="text-muted position-relative"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    href="#offcanvasExample"
                    role="button"
                    aria-controls="offcanvasRight"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-shopping-bag"
                    >
                      <path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path>
                      <line x1="3" y1="6" x2="21" y2="6"></line>
                      <path d="M16 10a4 4 0 0 1-8 0"></path>
                    </svg>
                    <span
                      v-if="profileName"
                      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"
                    >
                      <span>{{ cart.length }}</span>
                      <span class="visually-hidden">unread messages</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="border-bottom pb-lg-4 pb-3" v-if="!isAuthPage">
      <nav class="navbar navbar-expand-lg navbar-light navbar-default pt-0 pb-0">
        <div class="container px-0 px-md-3">
          <div class="dropdown me-3 d-none d-lg-block">
            <button
              class="btn btn-primary px-6"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="me-1">
                <i class="feather-icon icon-plus-square me-2"></i>
              </span>
              ยาและเวชภัณฑ์
            </button>

            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li v-for="(item, index) in categoryDrug.category_sub">
                <a class="dropdown-item" href="#">{{ item.product_category_sub_name }}</a>
              </li>
            </ul>
          </div>

          <div class="offcanvas offcanvas-start p-4 p-lg-0" id="navbar-default">
            <div class="d-flex justify-content-between align-items-center mb-2 d-block d-lg-none">
              <a href="./index.html" class="text-center"
                ><img src="@/assets/images/logo/cobens-logo.svg" alt="eCommerce HTML Template" class="w-50"
              /></a>
              <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>

            <div class="d-block d-lg-none mb-2 pt-2">
              <a
                class="btn btn-primary w-100 d-flex justify-content-center align-items-center"
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <span class="me-2"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-grid"
                  >
                    <rect x="3" y="3" width="7" height="7"></rect>
                    <rect x="14" y="3" width="7" height="7"></rect>
                    <rect x="14" y="14" width="7" height="7"></rect>
                    <rect x="3" y="14" width="7" height="7"></rect></svg
                ></span>
                ยาและเวชภัณฑ์
              </a>
              <div class="collapse mt-2" id="collapseExample">
                <div class="card card-body">
                  <ul class="mb-0 list-unstyled">
                    <li v-for="(item, index) in category">
                      <a class="dropdown-item" href="#">{{ item.id + item.product_category_name }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="d-lg-none d-block mb-3">
              <button
                type="button"
                class="btn btn-outline-gray-400 text-muted w-100"
                data-bs-toggle="modal"
                data-bs-target="#locationModal"
              >
                <i class="feather-icon icon-map-pin me-2"></i>Pick Location
              </button>
            </div>
            <div class="d-none d-lg-block">
              <ul class="navbar-nav">
                <li class="nav-item dropdown" v-for="(item, index) in category">
                  <a
                    v-if="index < 6 && index > 0"
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {{ item.product_category_name }}
                  </a>
                  <ul v-if="index < 5 && index > 0 && item.category_sub.length > 0" class="dropdown-menu">
                    <li v-for="(itemSub, indexSub) in item.category_sub">
                      <a
                        :href="'/search/' + item.product_category_name + '/' + itemSub.product_category_sub_name"
                        class="dropdown-item"
                        >{{ itemSub.product_category_sub_name }}</a
                      >
                    </li>
                  </ul>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" role="button"> บทความ </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#" role="button"> ติดต่อเรา </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-4">
        <div class="modal-header border-0">
          <h5 class="modal-title fs-3 fw-bold" id="userModalLabel">เข้าสู่ระบบ</h5>

          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="mb-3">
              <label for="email" class="form-label">Email address</label>
              <input
                type="email"
                class="form-control"
                id="email"
                placeholder="Enter Email address"
                required=""
                v-model="email"
              />
            </div>

            <div class="mb-5">
              <label for="password" class="form-label">Password</label>
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="Enter Password"
                required=""
                v-model="password"
              />
            </div>

            <button type="button" class="btn btn-primary" @click="login()">เข้าสู่ระบบ</button>
          </form>
        </div>
        <div class="modal-footer border-0 justify-content-center">
          <button id="btnClose" type="button" class="btn btn-secondary d-none" data-bs-dismiss="modal">Close</button>
          เพิ่งเคยเข้ามาใน Cobens ใช่หรือไม่? <router-link to="register" @click="closeModal()">สมัครสมาชิก</router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header border-bottom">
      <div class="text-start">
        <h5 id="offcanvasRightLabel" class="mb-0 fs-4">Shop Cart</h5>
      </div>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
      <div class="">
        <ul class="list-group list-group-flush">
          <p v-if="cart.length > 0">Order Number : {{ cart[0].number }}</p>
          <!-- list group -->
          <li class="list-group-item py-3 ps-0 border-top" v-for="(item, index) in cart">
            <!-- row -->
            <div class="row align-items-center">
              <div class="col-3 col-md-2">
                <!-- img -->
                <img
                  :src="this.domain + 'images/' + item.product.image_main"
                  alt="{{ item.product.product_name }}"
                  class="img-fluid"
                />
              </div>
              <div class="col-4 col-md-6 col-lg-5">
                <!-- title -->
                <a :href="'/single/' + item.product.id" class="text-inherit">
                  <h6 class="mb-0">{{ item.product.product_name }}</h6>
                </a>
                <span
                  ><small class="text-muted">{{ item.product.net_volume }} / kg</small></span
                >
                <!-- text -->
                <div class="mt-2 small lh-1">
                  <a href="#!" class="text-decoration-none text-inherit" @click="removeCart(item.id)">
                    <span class="me-1 align-text-bottom">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-trash-2 text-success"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        <line x1="10" y1="11" x2="10" y2="17"></line>
                        <line x1="14" y1="11" x2="14" y2="17"></line></svg></span
                    ><span class="text-muted">Remove</span></a
                  >
                </div>
              </div>

              <div class="col-3 col-md-3 col-lg-3">
                <div class="input-group input-spinner">
                  <input
                    type="button"
                    value="-"
                    class="button-minus btn btn-sm"
                    data-field="quantity"
                    @click="shopRemove(item.id, item.amount, item.product.price_sale)"
                  />
                  <input
                    type="number"
                    step="1"
                    max="10"
                    :value="item.amount"
                    name="quantity"
                    class="quantity-field form-control-sm form-input"
                  />
                  <input
                    type="button"
                    value="+"
                    class="button-plus btn btn-sm"
                    data-field="quantity"
                    @click="shopAdd(item.id, item.amount, item.product.price_sale)"
                  />
                </div>
              </div>
              <!-- price -->
              <div class="col-2 text-lg-end text-start text-md-end col-md-2">
                <span class="fw-bold">฿{{ item.product.price_sale }}.00</span>
              </div>
            </div>
          </li>
        </ul>

        <div class="d-flex justify-content-between mt-4">
          <a href="javascript:void(0)" data-bs-dismiss="offcanvas" class="btn btn-primary">Continue Shopping</a>
          <a href="/cart" class="btn btn-dark">Update Cart</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
body {
  font-family: 'IBM Plex Sans Thai', sans-serif;
}
</style>

<script>
import { defineComponent } from 'vue'
import { useCartStore } from '@/stores/cart'
import { useCategoryStore } from '@/stores/category'
import axios from 'axios'
import { useMeta } from 'vue-meta'

export default defineComponent({
  setup() {
    let data = [];
    let title = "Cobens";
 //useMeta({ title: title })
    // axios.get('/seo').then((response) => {
    //   data = response.data[0]
    //   console.log(data.seo_title)
    //    title = data.seo_title
    //    useMeta({ title: title })
    // })
   // setTimeout(() => {
     // useMeta({ title: "โคเบน – ร้านเพื่อสุขภาพที่ดี วิตามินซี คอลลาเจน – Cobens Health Care" })
    //}, 5000);

    const counter = useCartStore()
    const categoryStore = useCategoryStore()
    return {
      counter,
      categoryStore
    }
  },

  inject: ['url_api', 'url_domain_api'],
  data() {
    return {
      domain: this.url_domain_api,
      api: this.url_api,
      navbarExpanded: false,
      category: [],
      categoryDrug: [],
      user: false,
      userData: [],
      profileName: '',
      cart: [],
      name: '',
      email: '',
      password: '',
      topBar: '',
      isModalVisible: false,
      isAuthPage: false,
      title: 'Cobens',
      description : 'Cobens',
      keywords : 'cobens'
    }
  },
 metaInfo () {
      return {
        title: this.title,
        meta: [
          { vmid: 'description', name: 'description', content: this.description },
          { vmid: 'keywords', name: 'keywords', content: this.keywords }
        ]
      }
    },
  watch: {
    counter: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (this.user) {
          this.getCart()
        }
      }
    }
  },
  mounted() {
    this.showTitle();
    var get_current_url = window.location.pathname

    if (get_current_url == '/login' || get_current_url == '/register') {
      this.isAuthPage = true
    } else {
      this.isAuthPage = false
    }
    this.getListCategory()
    this.getTopBar()
    //if (localStorage.getItem('token')) {
    this.getProfile()
    //}
  },
  methods: {
   async showTitle() {
     await axios.get('/seo').then((response) => {
      let data = response.data[0]
      console.log(data.seo_title)
       this.title = data.seo_title
       this.keywords = data.meta_keyword
       this.description = data.meta_desc
  
    })
    },
    async shopAdd(id, amountNow, price) {
      await axios
        .put('/cart/' + id, {
          amount: amountNow + 1,
          price: price
        })
        .then((response) => {
          this.getCart()
        })
    },
    shopRemove(id, amountNow, price) {
      let checkAmount = amountNow - 1
      if (checkAmount == 0 || checkAmount < 1) {
        this.removeCart(id)
      } else {
        axios
          .put('/cart/' + id, {
            amount: checkAmount,
            price: price
          })
          .then((response) => {
            this.getCart()
          })
      }
    },
    async removeCart(id) {
      await axios.delete('/cart/' + id).then((response) => {
        this.getCart()
      })
    },
    closeModal() {
      $('#btnClose').trigger('click')
    },
    async getTopBar() {
      await axios.get('/top-bar', {}).then((response) => {
        this.topBar = response.data[0].detail
      })
    },
    async getListCategory() {
      await axios.get('/product-category', {}).then((response) => {
        this.category = response.data
        this.categoryStore.category = response.data
        this.categoryDrug = this.category[0]
      })
    },
    async getCart() {
      await axios.get('/cart-user/' + this.userData.id, {}).then((response) => {
        this.cart = response.data
      })
    },
    async getProfile() {
      await axios.get('/profile', {}).then((response) => {
        if (response.data.user) {
          this.user = true
          this.userData = response.data.user
          this.profileName = response.data.user.name
          this.getCart()
        } else {
          this.user = false
        }
      })
    },
    async login() {
      await axios
        .post('/login', {
          email: this.email,
          password: this.password
        })
        .then((response) => {
          localStorage.setItem('token', response.data.access_token)
          this.$router.push({ path: '/' }).then(() => {
            this.$router.go()
          })
        })
    },
    async register() {
      await axios
        .post('/register', {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.password,
          role: 'member'
        })
        .then((response) => {
          console.log(response.data)
          this.$router.push({ path: 'login' })
        })
    },

    toggleNavbar() {
      this.navbarExpanded = !this.navbarExpanded
    },
    collapseNavbar() {
      this.navbarExpanded = false
    }
  }
})
</script>
