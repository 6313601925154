<template>
  <main>
    <section>
      <div class="container">
        <div class="row">
          <MenuAccount menu="address" />

          <div class="col-lg-9 col-md-8 col-12">
            <div class="py-6 p-md-6 p-lg-10">
              <div class="d-flex justify-content-between mb-6">
                <!-- heading -->
                <h3 class="mb-0">ที่อยู่ผู้รับสินค้า</h3>
                <!-- button -->
                <a href="#" class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#addAddressModal"
                  >เพิ่มที่อยู่จัดส่ง
                </a>
              </div>
              <div class="row">
                <div class="col-lg-5 col-xxl-4 col-12 mb-4" v-for="item in addressList">
                  <!-- form -->
                  <div class="card">
                    <div class="card-body p-6">
                      <div class="form-check mb-4">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="homeRadio" checked v-if="item.status_default" />
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="homeRadio"  v-if="item.status_default == 0" />
                        <label class="form-check-label text-dark fw-semi-bold" for="homeRadio"> {{ item.address_name }} </label>
                      </div>
                      <!-- address -->
                      <p class="mb-6">
                       {{ item.name }}<br />

                       {{ item.address }} <br />

                       {{ item.tumbol }} {{ item.amphur }} {{ item.province }} {{ item.remark }}<br />

                        {{ item.phone }}
                      </p>
                      <!-- btn -->
                      <a href="#" v-if="item.status_default == 0" class="btn btn-info btn-sm" @click="setDefault(item.id)">ตั้งเป็นที่อยู่เริ่มต้น</a>
                      <a href="#" v-if="item.status_default" class="btn btn-success btn-sm" >ค่าเริ่มต้น</a>
                      <div class="mt-4">
                        <a href="#" class="text-inherit">แก้ไข </a>
                        <a href="#" class="text-danger ms-3" data-bs-toggle="modal" data-bs-target="#deleteModal">ลบ </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="addressList.length == 0">
                <div class="col-md-12">
                  <p class="text-center text-muted">ไม่พบที่อยู่สำหรับจัดส่ง</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>

  <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <!-- modal content -->
      <div class="modal-content">
        <!-- modal header -->
        <div class="modal-header">
          <h5 class="modal-title" id="deleteModalLabel">Delete address</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!-- modal body -->
        <div class="modal-body">
          <h6>Are you sure you want to delete this address?</h6>
        </div>
        <!-- modal footer -->
        <div class="modal-footer">
          <!-- btn -->
          <button type="button" class="btn btn-outline-gray-400" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger">Delete</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="addAddressModal" tabindex="-1" aria-labelledby="addAddressModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <!-- modal content -->
      <div class="modal-content">
        <!-- modal body -->
        <div class="modal-body p-6">
          <div class="d-flex justify-content-between mb-5">
            <div>
              <!-- heading -->
              <h5 class="mb-1" id="addAddressModalLabel">ที่อยู่ผู้รับสินค้าใหม่</h5>
              <p class="small mb-0">เพิ่มที่อยู่จัดส่งสำหรับรับสินค้า</p>
            </div>
            <div>
              <!-- button -->
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
          </div>
          <!-- row -->
          <div class="row g-3">
            <div class="col-12">
              <input type="text" class="form-control" placeholder="ชื่อสถานที่" required="" v-model="address_name"/>
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="ชื่อผู้รับสินค้า" aria-label="name" required="" v-model="name"/>
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="ที่อยู่" v-model="address"/>
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="ตำบล/แขวง" v-model="tumbol"/>
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="อำเภอ/เขต" v-model="amphur"/>
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="จังหวัด" v-model="province"/>
            </div>
             <div class="col-12">
              <input type="text" class="form-control" placeholder="รหัสไปรษณีย์" v-model="remark" />
            </div>
            <div class="col-12">
              <input type="text" class="form-control" placeholder="หมายเลขโทรศัพท์" v-model="phone"/>
            </div>
           
            <div class="col-12">
              <!-- form check -->
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value=""  v-model="status_default" id="flexCheckDefault" />
                <label class="form-check-label" for="flexCheckDefault"> ตั้งค่าเริ่มต้น </label>
              </div>
         
            </div>

            <div class="col-12 text-end">
              <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal" id="btnClose">ยกเลิก</button>
              <button class="btn btn-primary" type="button" @click="addAddress()">บันทึกที่อยู่</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MenuAccount from './MenuAccount'
import $ from 'jquery'
export default {
  inject: ['url_api'],
  components: {
    MenuAccount
  },
  data() {
    return {
      user: [],
      addressList: [],
      address: '',
      tumbol: '',
      created_by: '',
      amphur: '',
      province: '',
      phone: '',
      remark: '',
      address_name: '',
      name: '',
      status_default: false
    }
  },
  mounted() {
    this.getProfile();
 
  },
  methods: {
    async getProfile() {
      await axios.get('/profile', {}).then((response) => {
        if (response) {
          this.user = response.data.user;
          this.getAddress()
        } else {
          this.$router.push({ path: 'login' })
        }
      })
    },
    async getAddress() {
      await axios.get('address-user/' + this.user.id, {}).then((response) => {
        if (response) {
          this.addressList = response.data
          console.log(this.addressList)
        }
      })
    },
    closeModal() {
      $('#btnClose').trigger('click')
    },
    async addAddress() {
      if(this.status_default){
        this.status_default = 1;
      }else{
        this.status_default = 0;
      }
      await axios
        .post('/address/', {
          user_id: this.user.id,
          address: this.address,
          tumbol: this.tumbol,
          created_by: this.user.id,
          updated_by: this.user.id,
          amphur: this.amphur,
          province: this.province,
          phone: this.phone,
          remark: this.remark,
          address_name: this.address_name,
          name: this.name,
          status_default: this.status_default
        })
        .then((response) => {
          // this.getAddress();
          location.reload();
          // this.closeModal()
        })
    },
    async setDefault(id) {
      await axios
        .put('/address/'+id, {
          user_id: this.user.id,
          status_default: 1
        })
        .then((response) => {
          location.reload();
        })
    },
  }
}
</script>
