<template>
   <main>
  <!-- section -->
  <section class="my-lg-14 my-8">
    <div class="container">
      <!-- row -->
      <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
          <!-- img -->
          <img src="../assets/images/svg-graphics/signin-g.svg" alt="" class="img-fluid">
        </div>
        <!-- col -->
        <div class="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1">
          <div class="mb-lg-5">
            <h1 class="mb-1 h2 fw-bold">ล็อคอิน / เข้าสู่ระบบ</h1>
            
          </div>

          <form>
            <div class="row g-3">
              <!-- row -->

              <div class="col-12">
                <!-- input -->
                <input type="email" class="form-control" id="inputEmail4" placeholder="Email" v-model="email" required>
              </div>
              <div class="col-12">
                <!-- input -->
                <input type="password" class="form-control" id="inputPassword4" placeholder="Password" v-model="password" required>
              </div>
              <div class="d-flex justify-content-between">
                <!-- form check -->
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                  <!-- label --> <label class="form-check-label" for="flexCheckDefault">
                    Remember me
                  </label>
                </div>
                <div> Forgot password? <a href="/register">Reset It</a></div>
              </div>
              <!-- btn -->
              <div class="col-12 d-grid"> <button type="button" @click="login()" class="btn btn-primary">เข้าสู่ระบบ</button>
              </div>
              <!-- link -->
              <div>เพิ่งเคยเข้ามาใน Cobens ใช่หรือไม่? <a href="/register"> สมัครสมาชิก</a></div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

</main>
</template>

<script>
import axios from 'axios'
import { useRouter } from 'vue-router';
export default {
  inject: ['url_api'],
  data() {
    return {
      api: this.url_api,
      email: '',
      password: ''
    }
  },
  mounted() {
    
  },
  methods: {
    
    async login() {
    
      await axios.post('/login', {
          'email' : this.email,
          'password' : this.password,
      }).then((response) => {
        localStorage.setItem('token', response.data.access_token);
        this.$router.push({ path:'/' }).then(() => { this.$router.go() });
      })
    },

  }
}
</script>
