import Home from '../views/Home.vue'
import Single from '../views/Single.vue'
import Product from '../views/Product.vue'
import Cart from '../views/Cart.vue'
import Checkout from '../views/Checkout.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import AccountSetting from '../views/AccountSetting.vue'
import Search from '../views/Search.vue'
import YourOrder from '../views/YourOrder.vue'
import Address from '../views/Address.vue'
import { createRouter, createWebHistory } from 'vue-router'

// const history = createWebHashHistory()
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      layout: 'default'
    },
    props: true
  },
  {
    path: '/single/:id',
    name: 'Single',
    component: Single,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/search/:category?/:sub?',
    name: 'Search',
    component: Search,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/single/:id',
    name: 'Single',
    component: Single,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/products/:id',
    name: 'Product',
    component: Product,
    meta: {
      layout: 'default'
    }
  },
  
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/account-setting',
    name: 'AccountSetting',
    component: AccountSetting,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/your-order',
    name: 'YourOrder',
    component: YourOrder,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/address',
    name: 'Address',
    component: Address,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      layout: 'default'
    }
  }
]
const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(),
  routes
})

export { router }
