import App from './App.vue'
import Default from '@/layouts/default/Default.vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { router } from './router'
import axios from 'axios'
import unoverlay from 'unoverlay-vue'
import { VueMeta } from "vue-meta";
import { createMetaManager } from "vue-meta";
import { plugin as vueMetaPlugin } from "vue-meta";
axios.defaults.baseURL = 'https://api.cobens.co/api/';
//axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
axios.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;


const app = createApp(App)
app.provide('url_api', 'https://api.cobens.co/api/')
app.provide('url_domain_api', 'https://api.cobens.co/')
app.component('LayoutDefault', Default)
app.use(createPinia())
app.use(unoverlay)
app.use(router)
//app.use(VueMeta)
app.use(VueMeta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
  })
app.use(vueMetaPlugin);
app.use(createMetaManager());
app.mount('#app')
