<template>
  <section class="mb-lg-14 mb-8 mt-8">
    <div class="container">
      <!-- row -->
      <div class="row">
        <div class="col-12">
          <!-- card -->
          <div class="card py-1 border-0 mb-8">
            <div>
              <h1 class="fw-bold">Shop Cart</h1>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
      <div class="row">
        <div class="col-lg-8 col-md-7">
          <div class="py-3">
            <!-- alert -->
            <div class="alert alert-danger p-2" role="alert">
              You’ve got FREE delivery. Start <a href="#!" class="alert-link">checkout now!</a>
            </div>
            <ul class="list-group list-group-flush" v-if="cart">
              <!-- list group -->
              <li v-for="(item, index) in cart" class="list-group-item py-3 py-lg-0 px-0 border-top">
                <!-- row -->
                <div class="row align-items-center">
                  <div class="col-3 col-md-2">
                    <!-- img -->
                    <img
                      :src="this.domain + 'images/' + item.product.image_main"
                      alt="{{ item.product.product_name }}"
                      class="img-fluid"
                    />
                  </div>
                  <div class="col-4 col-md-5">
                    <!-- title -->
                    <router-link to="/single" class="text-inherit"
                      ><h6 class="mb-0">{{ item.product.product_name }}</h6></router-link
                    >
                    <span
                      ><small class="text-muted">{{ item.product.net_volume }} / kg</small></span
                    >
                    <!-- text -->
                    <div class="mt-2 small lh-1">
                      <a href="#!" class="text-decoration-none text-inherit" @click="removeCart(item.id)">
                        <span class="me-1 align-text-bottom">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-trash-2 text-success"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line></svg></span
                        ><span class="text-muted">Remove</span></a
                      >
                    </div>
                  </div>
                  <!-- input group -->
                  <div class="col-3 col-md-3 col-lg-2">
                    <!-- input -->
                    <div class="input-group input-spinner">
                      <input
                        type="button"
                        value="-"
                        class="button-minus btn btn-sm"
                        data-field="quantity"
                        @click="shopRemove(item.id, item.amount, item.product.price)"
                      />
                      <input
                        type="number"
                        step="1"
                        max="10"
                        :value="item.amount"
                        name="quantity"
                        class="quantity-field form-control-sm form-input"
                      />
                      <input
                        type="button"
                        value="+"
                        class="button-plus btn btn-sm"
                        data-field="quantity"
                        @click="shopAdd(item.id, item.amount, item.product.price)"
                      />
                    </div>
                  </div>
                  <!-- price -->
                  <div class="col-2 text-lg-end text-start text-md-end col-md-2">
                    <span class="fw-bold">฿{{ (item.product.price_sale * item.amount).toLocaleString() }}.00</span>
                  </div>
                </div>
              </li>
            </ul>
            <!-- btn -->
            <div class="d-flex justify-content-between mt-4">
              <a href="/search" class="btn btn-primary">Continue Shopping</a>
            </div>
          </div>
        </div>

        <!-- sidebar -->
        <div class="col-12 col-lg-4 col-md-5">
          <!-- card -->
          <div class="mb-5 card mt-6">
            <div class="card-body p-6">
              <!-- heading -->
              <h2 class="h5 mb-4">Summary</h2>
              <div class="card mb-2">
                <!-- list group -->
                <ul class="list-group list-group-flush">
                  <!-- list group item -->
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">
                      <div>ราคารวม</div>
                    </div>
                    <span>฿{{ total.toLocaleString() }}.00</span>
                  </li>

                  <!-- list group item -->
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">
                      <div>ค่าขนส่ง</div>
                    </div>
                    <span>฿{{ (priceTransport[0]) }}.00</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">
                      <div>ค่าธรรมเนียมและบริการ</div>
                    </div>
                    <span>฿{{ fee.toLocaleString() }}</span>
                  </li>

                  <!-- list group item -->
                  <li class="list-group-item d-flex justify-content-between align-items-start">
                    <div class="me-auto">
                      <div class="fw-bold">ที่ต้องชำระรวมสุทธิ</div>
                    </div>
                    <span class="fw-bold">฿{{ (total + priceTransport[0] + fee).toLocaleString() }}</span>
                  </li>
                </ul>
              </div>
              <div class="d-grid mb-1 mt-4">
                <!-- btn -->
                <a href="/checkout" class="btn btn-primary btn-lg d-flex justify-content-between align-items-center">
                  ไปที่ชำระเงิน<span class="fw-bold">฿{{ (total + priceTransport[0] + fee).toLocaleString() }}</span>
                </a>
              </div>
              <!-- text -->
              <p>
                <small
                  >By placing your order, you agree to be bound by the Freshcart <a href="#!">Terms of Service</a> and
                  <a href="#!">Privacy Policy.</a>
                </small>
              </p>

              <!-- heading -->
              <div class="mt-8 d-none">
                <h2 class="h5 mb-3">Add Promo or Gift Card</h2>
                <form>
                  <div class="mb-2">
                    <!-- input -->
                    <label for="giftcard" class="form-label sr-only">Email address</label>
                    <input type="text" class="form-control" id="giftcard" placeholder="Promo or Gift Card" />
                  </div>
                  <!-- btn -->
                  <div class="d-grid"><button type="submit" class="btn btn-outline-dark mb-1">Redeem</button></div>
                  <p class="text-muted mb-0"><small>Terms & Conditions apply</small></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import { useCartStore } from '@/stores/cart'

export default defineComponent({
  setup() {
    const counter = useCartStore()
    return {
      counter
    }
  },
  inject: ['url_api', 'url_domain_api'],
  name: 'Home',
  data() {
    return {
      api: this.url_api,
      domain: this.url_domain_api,
      cart: [],
      user: [],
      fee: 0,
      weight: 0,
      total: 0,
      transport: [],
      priceTransport: [],
      priceTransportSelect: 0
    }
  },
  mounted() {
    if (localStorage.getItem('token')) {
      this.getProfile()
      this.getTransport()
    }
  },
  methods: {
    async getProfile() {
      await axios.get('/profile', {}).then((response) => {
        if (response) {
          this.user = response.data.user
          this.getCart()
        }
      })
    },
    async getTransport() {
      await axios.get('https://api.cobens.co/api/transport', {}).then((response) => {
        this.transport = response.data
        this.getCart()
      })
    },
    async getCart() {
      this.total = 0
      this.counter.cart++
      await axios.get('/cart-user/' + this.user.id, {}).then((response) => {
        this.total = 0;
        this.weight = 0;
        this.cart = response.data
        for (let i = 0; i < this.cart.length; i++) {
          this.total += this.cart[i].product.price_sale * this.cart[i].amount;

          this.weight += this.cart[i].product.net_volume;
         
        }

        this.priceTransport.length = this.transport.length
        for (let i = 0; i < this.transport.length; i++) {
          for (let j = 0; j < this.transport[i].price.length; j++) {
            if (this.weight <= this.transport[i].price[j].weight) {
              this.priceTransport[i] = this.transport[i].price[j].price
            }
          }
        }

        this.fee = (this.total + this.priceTransport[0]) * 0.0345;
        
      })
    },
    async shopAdd(id, amountNow, price) {
      await axios
        .put('/cart/' + id, {
          amount: amountNow + 1,
          price: price
        })
        .then((response) => {
          this.getCart()
        })
    },
    shopRemove(id, amountNow, price) {
      let checkAmount = amountNow - 1
      if (checkAmount == 0 || checkAmount < 1) {
        this.removeCart(id)
      } else {
        axios
          .put('/cart/' + id, {
            amount: checkAmount,
            price: price
          })
          .then((response) => {
            this.getCart()
          })
      }
    },
    async removeCart(id) {
      await axios.delete('/cart/' + id).then((response) => {
        this.getCart()
      })
    }
  }
})
</script>

