<template>
  <div class="">
    <main>
      <section class="mt-8">
        <div class="container">
          <swiper
            class="mySwiper hero-slider"
            :modules="modules"
            :loop="true"
            :pagination="true"
            :autoplay="{
              delay: 2000,
              disableOnInteraction: false
            }"
          >
            <swiper-slide
              v-for="(item, index) in bannerSlide"
              class="bg-slide"
              :style="{ 'background-image': 'url(' + this.domain + 'images/' + item.slide_image_path + ')' }"
            >
              <div class="ps-lg-12 py-lg-16 col-xxl-5 col-md-7 py-14 px-8 text-xs-center">
                <span class="badge text-bg-warning">{{ item.badg_text }}</span>

                <h2 class="text-dark display-5 fw-bold mt-4">{{ item.header }}</h2>
                <p class="lead">{{ item.sub_header }}</p>
                <a href="#!" class="btn btn-dark mt-3"
                  >{{ item.button_link }}<i class="feather-icon icon-arrow-right ms-1"></i
                ></a>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </section>

      <!-- Category Section Start-->
      <section class="mb-lg-10 mt-lg-14 my-8">
        <div class="container">
          <div class="row">
            <div class="col-12 mb-6">
              <h3 class="mb-0">Featured Categories</h3>
            </div>
          </div>
          <div class="category-slider">
            <swiper
              :slidesPerView="6"
              :spaceBetween="30"
              :pagination="{
                clickable: true
              }"
              :autoplay="{
                delay: 2500,
                disableOnInteraction: false
              }"
              :loop="true"
              :modules="modules"
              class="mySwiper"
            >
              <swiper-slide v-for="(item, index) in category"
                ><div class="item">
                  <router-link :to="'search/' + item.product_category_name" class="text-decoration-none text-inherit">
                    <div class="card card-product mb-lg-4">
                      <div class="card-body text-center py-8">
                        <img :src="this.domain + 'images/' + item.image_path" :alt="item.product_category_name" class="mb-3" />
                        <div class="text-truncate">{{ item.product_category_name }}</div>
                      </div>
                    </div>
                  </router-link>
                </div></swiper-slide
              >
            </swiper>
          </div>
        </div>
      </section>
      <!-- Category Section End-->
      <section>
        <div class="container">
          <div class="row">
            <div v-for="(item, index) in banner" class="col-12 col-md-6 mb-3 mb-lg-0">
              <div>
                <div
                  class="py-10 px-8 rounded-3"
                  :style="{
                    background: 'url(' + this.domain + 'images/' + item.banner_image_path + ')'
                  }"
                >
                  <div>
                    <h3 class="fw-bold mb-1">{{ item['header'] }}</h3>
                    <p class="mb-4">{{ item['sub_header'] }}</p>
                    <a href="#!" class="btn btn-dark">{{ item['button_link'] }}</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-12 col-md-6">
              <div>
                <div
                  class="py-10 px-8 rounded-3"
                  :style="{
                    background: 'url(' + require(`@/assets/images/banner/grocery-banner-2.jpg`) + ')'
                  }"
                >
                  <div>
                    <h3 class="fw-bold mb-1">Freshly Baked Buns</h3>
                    <p class="mb-4">Get Upto <span class="fw-bold">25%</span> Off</p>
                    <a href="#!" class="btn btn-dark">Shop Now</a>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </section>
      <!-- Popular Products Start-->
      <section class="my-lg-14 my-8">
        <div class="container">
          <div class="row">
            <div class="col-12 mb-6">
              <h3 class="mb-0">Popular Products</h3>
            </div>
          </div>

          <div class="row g-4 row-cols-lg-5 row-cols-2 row-cols-md-3">
            <div class="col" v-for="(item, index) in product">
              <div class="card card-product">
                <div class="card-body">
                  <div class="text-center position-relative">
                    <a :href="'/products/' + (item.url ? item.url : item.product_name)"
                      ><img :src="this.domain + 'images/' + item.image_main" :alt="item.product_name" class="mb-3 img-fluid"
                    /></a>
                    <div class="card-product-action">
                      <a :href="'/products/' + (item.url ? item.url : item.product_name)" class="btn-action"
                        ><i class="bi bi-eye" data-bs-toggle="tooltip" data-bs-html="true" title="Quick View"></i
                      ></a>
                      <a
                        href="javascript:void(0)"
                        class="btn-action"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title="Wishlist"
                        ><i class="bi bi-heart"></i
                      ></a>
                    </div>
                  </div>
                  <div class="text-small mb-1">
                    <a :href="'/search'" class="text-decoration-none text-muted">
                      <small v-if="item.brand">{{ item.brand.brand_name }}</small>
                      <small v-if="!item.brand">ไม่ระบุ</small>
                    </a>
                  </div>
                  <h2 class="fs-6">
                    <a :href="'/products/' + (item.url ? item.url : item.product_name)" class="text-inherit text-decoration-none">{{ item.product_name }}</a>
                  </h2>
                  <div>
                    <small class="text-warning">
                      <i class="bi bi-star-fill"></i>
                      <i class="bi bi-star-fill"></i>
                      <i class="bi bi-star-fill"></i>
                      <i class="bi bi-star-fill"></i>
                      <i class="bi bi-star-fill"></i
                    ></small>
                    <span class="text-muted small">5 ({{ Math.floor(Math.random() * 999) + 600 }})</span>
                  </div>

                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div>
                      <span class="text-dark">฿{{ item.price_sale }}</span>
                      <span class="text-decoration-line-through text-muted p-1"> ฿{{ item.price }}</span>
                    </div>
                    <div>
                      <a v-if="login" class="btn btn-primary btn-sm" @click="addCart(item.id, item.price)">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-plus"
                        >
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                        Add</a
                      >
                      <a v-if="!login" href="/login" class="btn btn-primary btn-sm">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-plus"
                        >
                          <line x1="12" y1="5" x2="12" y2="19"></line>
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                        </svg>
                        Add</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Popular Products End-->
      <section>
        <div class="container">
          <div class="row">
            <div class="col-md-12 mb-6">
              <h3 class="mb-0">Flash Sale</h3>
            </div>
          </div>
          <div class="row row-cols-lg-4 row-cols-1 row-cols-md-2 g-4">
            <div class="col">
              <div
                class="pt-8 px-6 px-xl-8 rounded-3  h-100"
                :style="{
                  background: 'url(' + require(`@/assets/images/banner/FlashSales.jpg`) + ')'
                }"
              >
                <div>
                  <h3 class="fw-bold text-white">100% Enjoy Shopping</h3>
                  <p class="text-white">รับข้อเสนอที่ดีก่อนหมดเวลา</p>
                  <a href="#!" class="btn btn-primary">Shop Now <i class="feather-icon icon-arrow-right ms-1"></i></a>
                </div>
              </div>
            </div>
            <div class="col" v-for="item in flashSale">
              <div class="card card-product">
                <div class="card-body">
                  <div class="text-center position-relative">
                    <a :href="'/products/' + (item.url ? item.url : item.product_name)"
                      ><img :src="this.domain + 'images/' + item.image_main" :alt="item.product_name" class="mb-3 img-fluid"
                    /></a>

                    <div class="card-product-action">
                      <a :href="'/products/' + (item.url ? item.url : item.product_name)" class="btn-action" data-bs-toggle="tooltip" data-bs-html="true" title="Quick View"
                        ><i class="bi bi-eye"></i
                      ></a>
                      <a href="#!" class="btn-action" data-bs-toggle="tooltip" data-bs-html="true" title="Wishlist"
                        ><i class="bi bi-heart"></i
                      ></a>
                   
                    </div>
                  </div>
                  <div class="text-small mb-1">
                     <a :href="'/search'" class="text-decoration-none text-muted">
                      <small v-if="item.brand">{{ item.brand.brand_name }}</small>
                      <small v-if="!item.brand">ไม่ระบุ</small>
                    </a>
                  </div>
                  <h2 class="fs-6">
                    <a :href="'/products/' + (item.url ? item.url : item.product_name)" class="text-inherit text-decoration-none">{{ item.product_name }}</a>
                  </h2>

                  <div class="d-flex justify-content-between align-items-center mt-3">
                    <div>
                     <span class="text-dark">฿{{ item.price_sale }}</span>
                      <span class="text-decoration-line-through text-muted p-1"> ฿{{ item.price }}</span>
                    </div>
                    <div>
                      <small class="text-warning">
                        <i class="bi bi-star-fill"></i>
                        <i class="bi bi-star-fill"></i>
                        <i class="bi bi-star-fill"></i>
                        <i class="bi bi-star-fill"></i>
                        <i class="bi bi-star-fill"></i>
                      </small>
                      <span><small>5</small></span>
                    </div>
                  </div>
                  <div class="d-grid mt-2">
                    <a  v-if="login" @click="addCart(item.id, item.price)" href="#!" class="btn btn-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-plus"
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                      Add to cart
                    </a>
                    <a  v-if="!login"  href="/login" class="btn btn-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-plus"
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>
                      Add to cart
                    </a>
                  </div>
                  <div class="d-flex justify-content-start text-center mt-3">
                    <div class="deals-countdown w-100" data-countdown="2022/10/10 00:00:00"></div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <section class="my-lg-14 my-8">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div class="mb-8 mb-xl-0">
                <div class="mb-6"><img src="../assets/images/icons/clock.svg" alt="" /></div>
                <h3 class="h5 mb-3">10 minute grocery now</h3>
                <p>Get your order delivered to your doorstep at the earliest from FreshCart pickup stores near you.</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-8 mb-xl-0">
                <div class="mb-6"><img src="../assets/images/icons/gift.svg" alt="" /></div>
                <h3 class="h5 mb-3">Best Prices & Offers</h3>
                <p>
                  Cheaper prices than your local supermarket, great cashback offers to top it off. Get best pricess & offers.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-8 mb-xl-0">
                <div class="mb-6"><img src="../assets/images/icons/package.svg" alt="" /></div>
                <h3 class="h5 mb-3">Wide Assortment</h3>
                <p>
                  Choose from 5000+ products across food, personal care, household, bakery, veg and non-veg & other categories.
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="mb-8 mb-xl-0">
                <div class="mb-6"><img src="../assets/images/icons/refresh-cw.svg" alt="" /></div>
                <h3 class="h5 mb-3">Easy Returns</h3>
                <p>
                  Not satisfied with a product? Return it at the doorstep & get a refund within hours. No questions asked
                  <a href="#!">policy</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
   <Modal title="" v-model:visible="modalAdd"  width="350px" :closable=false>
    <div class="col-12 text-center" >
      <img class="w-15" src="https://cdn-icons-png.flaticon.com/512/4436/4436481.png">
    </div>
  </Modal>
</template>
<script lang="ts">
</script>
<script>
import { defineComponent } from 'vue'
import HelloWorld from '@/components/HelloWorld.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Pagination, Autoplay } from 'swiper'
import axios from 'axios'
import $ from 'jquery'
import 'swiper/css'
import 'swiper/css/pagination'
import { useCartStore } from '@/stores/cart'
import { Modal } from 'usemodal-vue3'

export default defineComponent({
  setup() {
    const counter = useCartStore()
    return {
      counter
    }
  },
  inject: ['url_api', 'url_domain_api'],
  name: 'Home',
  data() {
    return {
      api: this.url_api,
      domain: this.url_domain_api,
      modules: [Autoplay, Pagination],
      bannerSlide: [],
      category: [],
      product: [],
      flashSale: [],
      banner: [],
      user: [],
      testCount: '',
      login: false,
      modalAdd : false
    }
  },

  components: {
    HelloWorld,
    Swiper,
    SwiperSlide,
    Modal
  },
  watch: {
    testCount(newQuestion, oldQuestion) {
      console.log('5454545')
    }
  },
  mounted() {
    this.getListBannerSlide()
    this.getListCategory()
    this.getListProduct()
    this.getListBanner()
    this.getFlashSale()
    if (localStorage.getItem('token')) {
      this.getProfile()
      this.login = true
    }
    console.log(this.counter.cart)
  },
  methods: {
    async getProfile() {
      await axios.get('/profile', {}).then((response) => {
        if (response) {
          this.user = response.data.user
        }
      })
    },
    async getListBannerSlide() {
      await axios.get('/slide', {}).then((response) => {
        this.bannerSlide = response.data
      })
    },
    async getListCategory() {
      await axios.get('/product-category', {}).then((response) => {
        this.category = response.data
      })
    },
    async getListProduct() {
      await axios.get('/products', {}).then((response) => {
        this.product = response.data
      })
    },
    async getListBanner() {
      await axios.get('/banner', {}).then((response) => {
        this.banner = response.data
      })
    },

    async getFlashSale() {
      await axios.get('/product-flash-sale', {}).then((response) => {
        this.flashSale = response.data.product
      })
    },

    async addCart(id, price) {
      await axios
        .post('/cart', {
          product_id: id,
          user_id: this.user.id,
          status: 0,
          amount: 1,
          price: price
        })
        .then((response) => {
          this.counter.cart++
        })
    },
    onSwiper(swiper) {
      console.log(swiper)
    },
    onSlideChange() {
      console.log('slide change')
    }
  }
})
</script>
<style>
.bg-slide {
  background-size: cover;
  border-radius: 0.5rem;
  background-position: center;
}
.swiper {
  width: 100%;
  height: 100%;
}

/* .modal-vue3-footer{
  display:  none !important;
}

.modal-vue3-header{
  display:  none !important;
} */
/* .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
} */

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.swiper-slide .item img {
  display: inline-block;
  width: 120px;
}

.item {
  width: 204px;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}
.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}
</style>